import { ModelContext, ModelProvider } from "../Model/ModelContext";

import Model from "../Model/Model";
import React from 'react';
import styles from './Welcome.module.css'

function Welcome() {
    return (
        <ModelProvider>
        <div className={styles.welcome}>
            <div className={styles.text}>
                <h1>Explore new horisons</h1>
                <p>Web-development for those, who is looking for new approach</p>
                <a draggable="false" href="#">Discuss your project</a>
            </div>
            <div className={styles.model}>
                <Model dragable={false}></Model>
            </div>
        </div>
        </ModelProvider>
    );
}

export default Welcome
import React, { Suspense, useState } from "react";

import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import Planeta from "./Planeta";
import styles from "./Model.module.css";

// import Earth from "./Earth";




function Model({ dragable }) {
  const [isDragging, setIsDragging] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const handleMouseDown = () => {
    setIsMoving(true);
  };

  const handleMouseMove = () => {
    if (isMoving) {
      setIsDragging(true);
    }
  };

  const handleMouseUp = () => {
    setIsMoving(false);
    setIsDragging(false);
  };

  return (
    <Canvas
      className={styles.canvas}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <ambientLight intensity={0.5} />
      {/* <PerspectiveCamera makeDefault={false} far={100} near={0.1} fov={19.731} rotation={[-Math.PI / 2, 0, 0]} /> */}
      <pointLight
        intensity={10000}
        decay={20}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      {/* <directionalLight intensity={1} decay={2} rotation={[-Math.PI / 2, 0, 0]} /> */}
      <Suspense fallback={null}>
        <Planeta isDragging={isDragging} setIsDragging={setIsDragging} dragable={dragable} />
        {/* <OrbitControls /> */}
      </Suspense>
    </Canvas>
  );
}

export default Model;

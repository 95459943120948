import { React, useEffect, useRef, useState } from 'react';

import About from '../Components/About/About';
import AsciiArt from '../Components/AsciiArt/AsciiArt';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
import Projects from '../Components/Projects/Projects';
import Team from '../Components/Team/Team';
import Welcome from '../Components/Welcome/Welcome';
import styles from './Main.module.css'

function Main() {
    // const styles = isMobile ? mobileStyles : PCstyles;

    const parallaxRef = useRef(null);
    const cardRefs = [useRef(null), useRef(null), useRef(null)];
    const projRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const [scrollPosition, setScrollPosition] = useState(0);
    const [opacity1, setOpacity1] = useState(1);
    const [opacity2, setOpacity2] = useState(1);
    const [opacity3, setOpacity3] = useState(1);
    const [opacity4, setOpacity4] = useState(1);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (parallaxRef.current) {
    //             const scrollPosition = window.scrollY;
    //             parallaxRef.current.style.backgroundPosition = `center ${scrollPosition * 0.5}px`;
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         setScrollPosition(window.scrollY);
    //     };

    //     window.addEventListener('scroll', handleScroll);
        
    //     const speed = 500

    //     if (projRefs[0].current) {
    //         let top = projRefs[0].current.offsetTop
    //         let bottom = projRefs[0].current.offsetTop + projRefs[0].current.offsetHeight;            
    //         if (scrollPosition > bottom-600) {
    //             setOpacity1(Math.max(0, 1 - (scrollPosition - (bottom-400)) / speed));
    //         }
    //         if (scrollPosition < top-200) {
    //             setOpacity1(Math.max(0, 1 - ((top-200) - scrollPosition) / speed));
    //         }
    //     }

    //     if (projRefs[1].current) {
    //         let top = projRefs[1].current.offsetTop
    //         let bottom = projRefs[1].current.offsetTop + projRefs[1].current.offsetHeight;            
    //         if (scrollPosition > bottom-400) {
    //             setOpacity2(Math.max(0, 1 - (scrollPosition - (bottom-400)) / speed));
    //         }
    //         if (scrollPosition < top-200) {
    //             setOpacity2(Math.max(0, 1 - ((top-200) - scrollPosition) / speed));
    //         }
    //     }

    //     if (projRefs[2].current) {
    //         let top = projRefs[2].current.offsetTop
    //         let bottom = projRefs[2].current.offsetTop + projRefs[2].current.offsetHeight;            
    //         if (scrollPosition > bottom-400) {
    //             setOpacity3(Math.max(0, 1 - (scrollPosition - (bottom-400)) / speed));
    //         }
    //         if (scrollPosition < top-200) {
    //             setOpacity3(Math.max(0, 1 - ((top-200) - scrollPosition) / speed));
    //         }
    //     }

    //     if (projRefs[3].current) {
    //         let top = projRefs[3].current.offsetTop
    //         let bottom = projRefs[3].current.offsetTop + projRefs[3].current.offsetHeight;            
    //         if (scrollPosition > bottom-400) {
    //             setOpacity4(Math.max(0, 1 - (scrollPosition - (bottom-400)) / speed));
    //         }
    //         if (scrollPosition < top-200) {
    //             setOpacity4(Math.max(0, 1 - ((top-200) - scrollPosition) / speed));
    //         }
    //     }

    //     return () => {
            // window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [window.scrollY]);




    return (
        <>
            <Header></Header>

            <AsciiArt className={styles.underheader}></AsciiArt>
            <Welcome></Welcome>
            <About></About>

            <Projects></Projects>
            {/* <Team></Team> */}
        </>
    );
}

export default Main;

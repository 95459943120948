import { React, useEffect, useState } from 'react';

import logoImg from '../../Assets/img/ASCII/2.png'
import styles from './AsciiArt.module.css'

const AsciiArt = () => {
    const [ascii, setAscii] = useState('kaban');
    const [textStyle, setTextStyle] = useState(0.6)

    useEffect(() => {
        const img = new Image();
        img.src = logoImg;

        img.onload = () => {

            const charWidth = 374; // TO CONFIG in symbols
            const charRatio = 1.86; // TO CONFIG
            const logoPercent = 0.80; // TO CONFIG

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const aspectRatio = img.width / img.height * 1.66;
            // console.log(window.innerWidth / window.innerHeight)
            const screen_ratio = window.innerWidth / window.innerHeight


            canvas.width = charWidth * logoPercent; // Logo width in symbols
            canvas.height = canvas.width / aspectRatio;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
            const logoAscii = convertToASCII(imageData, canvas.width, canvas.height);
            const fullAscii = createLargeAsciiImage(logoAscii, canvas.width, charWidth, charWidth / charRatio / screen_ratio)

            animateAppearMeteor(fullAscii, 6, 60, 0.4, setAscii, () => { // TO CONFIG delay, steps, percent
                animateBlinking(fullAscii, 250, 0.1, setAscii, setTextStyle); // TO CONFIG delay and percent
            }, setTextStyle)
        };
    }, []);

    return (
        <div className={styles.ascii}>
            <pre style={{ opacity: textStyle }}> {ascii} </pre>
        </div>
    );
}


const convertToASCII = (data, width, height) => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz"
    const uppercaseAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let asciiArt = '';

    for (let y = 0; y < height; y += 1) {
        for (let x = 0; x < width; x += 1) {
            const offset = (y * width + x) * 4;
            const r = data[offset];
            const g = data[offset + 1];
            const b = data[offset + 2];
            const brightness = (r + g + b) / 3;
            if (brightness > 200) {
                asciiArt += ' ';
            }
            else if (brightness < 70) {
                const randomUppercaseLetter = uppercaseAlphabet[Math.floor(Math.random() * uppercaseAlphabet.length)];
                asciiArt += randomUppercaseLetter
            }
            else {
                const randomLowercaseLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
                asciiArt += randomLowercaseLetter
            }
        }
    }

    return asciiArt;
};

function createLargeAsciiImage(asciiStr, imgWidth, outputWidth, outputHeight) {
    const asciiStrLen = asciiStr.length;
    const imgHeight = Math.ceil(asciiStrLen / imgWidth);

    const smallAsciiImg = [];
    for (let i = 0; i < asciiStrLen; i += imgWidth) {
        smallAsciiImg.push(asciiStr.slice(i, i + imgWidth));
    }

    const largeAsciiImg = [];
    for (let i = 0; i < outputHeight; i++) {
        largeAsciiImg.push(Array(outputWidth).fill(" "));
    }

    const start_x = Math.floor((outputWidth - imgWidth) / 2);
    const start_y = Math.floor((outputHeight - imgHeight) / 2);

    for (let y = 0; y < imgHeight; y++) {
        for (let x = 0; x < imgWidth; x++) {
            largeAsciiImg[start_y + y][start_x + x] = smallAsciiImg[y][x];
        }
    }

    const asciiImg = largeAsciiImg.map(row => row.join("")).join("\n");
    return asciiImg;
}

function animateBlinking(asciiImg, delay = 100, percent = 0.1, setAscii, setTextStyle) {
    const img2DArray = asciiImg.split("\n").map(line => line.split(""));
    const imgHeight = img2DArray.length;
    const imgWidth = img2DArray[0].length;

    setTimeout(setTextStyle(0.95), 1000)

    const whitePixels = [];

    for (let x = 0; x < imgWidth; x++) {
        for (let y = 0; y < imgHeight; y++) {
            if (img2DArray[y][x] !== " ") {
                whitePixels.push([x, y]);
            }
        }
    }

    const disabledPixels = [];

    function setAsciiFrame(frame) {
        setAscii(frame.map(row => row.join("")).join("\n"));
    }

    function animateStep() {
        while (disabledPixels.length < whitePixels.length * percent) {
            const randomIndex = Math.floor(Math.random() * whitePixels.length);
            const [x, y] = whitePixels.splice(randomIndex, 1)[0];
            disabledPixels.push([x, y, img2DArray[y][x]]);
            img2DArray[y][x] = " ";
        }

        setAsciiFrame(img2DArray);

        setTimeout(() => {
            for (const [x, y, v] of disabledPixels) {
                img2DArray[y][x] = v;
                whitePixels.push([x, y]);
            }
            disabledPixels.length = 0;
            animateStep();
        }, delay);
    }

    animateStep();
}

function animateAppearMeteor(asciiImg, baseDelay = 100, steps = 30, meteorsMaxDelay = 0.2, setAscii, nextAnimation, setTextStyle) {
    const img2DArray = asciiImg.split("\n").map(line => line.split(""));
    const imgHeight = img2DArray.length;
    const imgWidth = img2DArray[0].length;

    const targetPixels = [];

    for (let x = 0; x < imgWidth; x++) {
        for (let y = 0; y < imgHeight; y++) {
            if (img2DArray[y][x] !== " ") {
                targetPixels.push([x, y]);
            }
        }
    }

    const borderPoints = [];

    for (let x = 0; x < imgWidth; x++) {
        borderPoints.push([x, 0]);
        borderPoints.push([x, imgHeight - 1]);
    }

    for (let y = 0; y < imgHeight; y++) {
        borderPoints.push([0, y]);
        borderPoints.push([imgWidth - 1, y]);
    }


    const maxDelay = meteorsMaxDelay * steps
    function powerDelay(delay) {
        const sharpness = 12
        const coeff = sharpness * Math.pow(delay / maxDelay - 0.5, 3) + sharpness * Math.pow(0.5, 3); // TO CONFIG
        return delay * coeff
    }

    const meteors = targetPixels.map(target => ({
        start: borderPoints[Math.floor(Math.random() * borderPoints.length)],
        target: target,
        symbol: img2DArray[target[1]][target[0]],
        delay: powerDelay(Math.floor(Math.random() * maxDelay)),
    }));

    function getMeteorPosition(meteor, steps, currentStep) {
        const coeff = Math.min(1, Math.max(0, (currentStep - meteor.delay) / steps));
        const x = Math.round(
            meteor.start[0] + (meteor.target[0] - meteor.start[0]) * coeff
        );
        const y = Math.round(
            meteor.start[1] + (meteor.target[1] - meteor.start[1]) * coeff
        );
        return [x, y];
    }

    function setAsciiFrame(frame) {
        setAscii(frame.map(row => row.join("")).join("\n"));
    }

    setAsciiFrame(img2DArray);

    const totalSteps = steps + Math.max(...meteors.map(meteor => meteor.delay));
    let currentStep = 0;

    function animateStep() {
        const currentFrame = Array.from({ length: imgHeight }, () =>
            Array(imgWidth).fill(" ")
        );
        for (const meteor of meteors) {
            const [x, y] = getMeteorPosition(meteor, steps, currentStep);
            if (x === meteor.start[0] && y === meteor.start[1]) {
                currentFrame[y][x] = " ";
            } else {
                currentFrame[y][x] = meteor.symbol;
            }
        }
        setAsciiFrame(currentFrame);

        if (currentStep < totalSteps) {
            currentStep++;

            const t = 1 + Math.pow(6 * (currentStep / totalSteps - 0.5), 2); // TO CONFIG
            const currentDelay = baseDelay * t;
            setTimeout(animateStep, currentDelay);
        } else {
            setAsciiFrame(img2DArray);

            setTextStyle(1)

            if (nextAnimation) {
                setTimeout(nextAnimation, 800); // TO CONFIG
            }
        }
    }

    animateStep();
}

export default AsciiArt

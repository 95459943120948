import { PerspectiveCamera, useGLTF } from '@react-three/drei'
import React, { useContext, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";

import { ModelContext } from "./ModelContext";

export default function Model({ isDragging, dragable }, props) {
  const { nodes, materials } = useGLTF('/planeta.gltf')
  const ref = useRef();
  const { mouse } = useThree();
  const { targetRotation, setTargetRotation } = useContext(ModelContext);

  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.x +=
        (targetRotation[0] - ref.current.rotation.x) * 0.1;
      ref.current.rotation.y +=
        (targetRotation[1] - ref.current.rotation.y) * 0.1;
      ref.current.rotation.z +=
        (targetRotation[2] - ref.current.rotation.z) * 0.1;
    }

    if (isDragging && dragable) {
      const deltaRotationY = (mouse.x * Math.PI) / 100;
      let deltaRotationX = (mouse.y * Math.PI) / 100;

      let newTargetRotationX = targetRotation[0] - deltaRotationX;

      const maxRotationX = Math.PI / 10;
      newTargetRotationX = Math.max(Math.min(newTargetRotationX, maxRotationX), -maxRotationX);
      
      setTargetRotation([
        newTargetRotationX,
        targetRotation[1] + deltaRotationY,
        targetRotation[2],
      ]);

      if (ref.current) {
        ref.current.rotation.set(...targetRotation);
      }

      // console.log(targetRotation)
    }
  });
  return (
    <group {...props} dispose={null} ref={ref} scale={2.1}>
      <group position={[10.058, 5.904, -1.005]} rotation={[1.89, 0.881, -2.045]}>
        <pointLight intensity={1000} decay={2} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
      <group position={[-1.475, -1.788, 6.242]} rotation={[1.852, 0.013, 0.044]}>
        <PerspectiveCamera makeDefault={false} far={100} near={0.1} fov={19.731} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
      <group position={[0, 0, 1.228]} scale={3.953}>
        <mesh geometry={nodes.map_1.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.map_2.geometry} material={materials['Material.001']} />
      </group>
      <mesh geometry={nodes.Icosphere.geometry} material={materials['Material.002']}>
        <group rotation={[Math.PI / 2, 0.588, -Math.PI / 4]}>
          <directionalLight intensity={0.7} decay={2} rotation={[-Math.PI / 2, 0, 0]} />
        </group>
      </mesh>

      
      <mesh geometry={nodes.sphere_wire.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.location.geometry} material={materials['Material.004']} position={[0, 0.314, 0.017]} />

       {/* LE */}
       <group onClick={() => dragable && setTargetRotation(getTargetRotation(3))}>
      <mesh geometry={nodes.Curve005.geometry} material={materials['Material.004']} position={[-0.078, 0.081, 1.084]} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.Curve006.geometry} material={materials['Material.007']} position={[-0.078, 0.081, 1.085]} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.Curve007.geometry} material={materials['SVGMat.026']} position={[-0.078, 0.081, 1.086]} rotation={[Math.PI / 2, 0, 0]} />
      </group>


      {/* Coffee */}
      <group onClick={() => dragable && setTargetRotation(getTargetRotation(1))}>
      <mesh geometry={nodes.Curve011.geometry} material={materials['Material.004']} position={[0.412, 0.073, 1.016]} rotation={[1.497, 0, -0.529]} scale={0.764} />
      <mesh geometry={nodes.Curve013.geometry} material={materials['SVGMat.011']} position={[0.412, 0.073, 1.018]} rotation={[1.497, 0, -0.529]} scale={0.764} />
      <mesh geometry={nodes.Curve014.geometry} material={materials['Material.007']} position={[0.412, 0.073, 1.017]} rotation={[1.497, 0, -0.529]} scale={0.764} />
      <mesh geometry={nodes.Curve004.geometry} material={materials['SVGMat.023']} position={[0.413, 0.073, 1.018]} rotation={[1.497, 0, -0.529]} scale={0.764} />
      </group>


      {/* Coffee */}
      <group onClick={() => dragable && setTargetRotation(getTargetRotation(1))}>
      <mesh geometry={nodes.Curve010.geometry} material={materials['SVGMat.003']} position={[0.101, 0.122, 1.081]} rotation={[Math.PI / 2, 0, -0.15]} scale={0.764} />
      <mesh geometry={nodes.Curve003.geometry} material={materials['SVGMat.002']} position={[0.101, 0.122, 1.081]} rotation={[Math.PI / 2, 0, -0.15]} scale={0.764} />
      <mesh geometry={nodes.Curve002.geometry} material={materials['Material.007']} position={[0.101, 0.122, 1.08]} rotation={[Math.PI / 2, 0, -0.15]} scale={0.764} />
      <mesh geometry={nodes.Curve001.geometry} material={materials['Material.004']} position={[0.101, 0.122, 1.078]} rotation={[Math.PI / 2, 0, -0.15]} scale={0.764} />
      </group>
    </group>
  )
}

useGLTF.preload('/planeta.gltf')

function getTargetRotation(location) {
  const projs = [
    [0.09209479790626196, 4.3724934995944515, 0],
    [-1.6331202126551339, 3.4226132592933523, 0],
    [-4.677018681751309, 3.527152647843037, 0],
    [0, 0, 0],
  ];
  return projs[location];
}

import Main from "./Main/Main";
import { React } from "react";

function App() {
  return (
    <div className="App">
      <Main></Main>
    </div>
  );
}

export default App;

import React, { createContext, useState } from 'react';

export const ModelContext = createContext();

export const ModelProvider = ({ children }) => {
  const [targetRotation, setTargetRotation] = useState([0, 0, 0]);
  

  return (
    <ModelContext.Provider value={{ targetRotation, setTargetRotation }}>
      {children}
    </ModelContext.Provider>
  );
};

import React, { useEffect, useRef, useState } from "react";

import First from "./Parts/First";
import Second from "./Parts/Second";

function Team() {
  const [isScrollLocked, setIsScrollLocked] = useState(false);
  const [activeComponent, setActiveComponent] = useState("first");
  const isScrollLockedRef = useRef(isScrollLocked);

  useEffect(() => {
    isScrollLockedRef.current = isScrollLocked;
  }, [isScrollLocked]);

  const handleComponentChange = (component) => {
    // console.log("locked", isScrollLocked);
    if (!isScrollLockedRef.current) {
      setActiveComponent(component);
      setIsScrollLocked(true);
    }
  };


  return (
    <div style={{ height: "1000vh" }}>
      <First
        style={{
          transform: `translateY(${
            activeComponent === "first" ? 0 : "-100vh"
          })`,
        }}
        onComponentChange={handleComponentChange}
        activeComponent={activeComponent}
        setIsScrollLocked={setIsScrollLocked}
      />

      <Second
        style={{
          transform: `translateY(${
            activeComponent === "second" ? 0 : "100vh"
          })`,
        }}
        onComponentChange={handleComponentChange}
        activeComponent={activeComponent}
        setIsScrollLocked={setIsScrollLocked}
      />
    </div>
  );
}

export default Team;

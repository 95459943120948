import { ModelContext, ModelProvider } from "../Model/ModelContext";
import { React, useContext, useEffect, useState } from "react";

import Model from "../Model/Model";
import styles from "./Projects.module.css";

function Projects() {
  function getTargetRotation(location) {
    const projs = [
      [0.09209479790626196, 4.3724934995944515, 0],
      [-1.6331202126551339, 3.4226132592933523, 0],
      [-4.677018681751309, 3.527152647843037, 0],
    ];
    return projs[location];
  }

  return (
    <ModelProvider>
      <div className={styles.container}>
        <div className={styles.info}>
          <div className={styles.logo}>*LOGO*</div>
          <div className={styles.model}>
            <Model dragable={true}></Model>
          </div>
          <div className={styles.text}>*INFO*</div>
        </div>

        <div className={styles.tiles}>
          <ProjectTile rotation={getTargetRotation(0)} />

          <ProjectTile rotation={getTargetRotation(1)} />

          <ProjectTile rotation={getTargetRotation(2)} />

          <ProjectTile rotation={[0, 0, 0]} />
        </div>
      </div>
    </ModelProvider>
  );
}

export default Projects;

function ProjectTile({ rotation }) {
  const { setTargetRotation } = useContext(ModelContext);

  return (
    <div className={styles.project} onClick={() => setTargetRotation(rotation)}>
      <div className={styles.preview}>*Devops lears JS2*</div>
      <div className={styles.name}>*SKYEL*</div>
    </div>
  );
}

import { React, useEffect, useState } from "react";

import left from "../../Assets/img/welcome/left.png";
import right from "../../Assets/img/welcome/right.png";
import styles from "./About.module.css";

function About() {
  const [scaleDiff, setScaleDiff] = useState(0);
  const vh = window.innerHeight / 100;
  // const styles = isMobile ? mobileStyles : PCstyles;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      setScaleDiff(Math.abs(scrollPosition / (vh * 100) / 8));
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.welcome}>
      <div className={styles.imgs}>
        <img
          draggable="false"
          src={right}
          style={{ transform: `scale(${0.7 + scaleDiff})` }}
        ></img>
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <img
            draggable="false"
            className={styles.dev}
            src={left}
            style={{ transform: `scale(${0.8 + scaleDiff})` }}
          ></img>
          <h1>
            Synergizing ideas,<br></br> Coding the future
          </h1>
        </div>

        <p>
          We offer the most advanced and balanced digital solutions, as well as
          full-cycle<br></br> digital presence in partnership with{" "}
          <span className={styles.underline}>VB Group</span>.
        </p>
      </div>
    </div>
  );
}

export default About;

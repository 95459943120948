import { React, useEffect, useState } from "react";

import logo from "./../../Assets/img/header/logo.svg"
import styles from "./Header.module.css";

function Header() {
  const [showHeader, setShowHeader] = useState(false);
  // const styles = isMobile ? mobileStyles : PCstyles;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 400) {
        setShowHeader(true)
      }
      else {
        setShowHeader(false)
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`${styles.header} ${showHeader && styles.show}`}>
      <img src={logo} className={styles.logo}></img>
      <ul className={styles.nav}>
        <li><a gref="#">About us</a></li>
        <li><a gref="#">Projects</a></li>
        <li><a gref="#">Team</a></li>
        <li><a gref="#">Contacts</a></li>
      </ul>
    </header>
  );
}

export default Header;
